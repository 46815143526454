import { ajaxRequest } from 'app/utils/helpers';
import { setServerMsg } from 'app/globalMessages/globalMessagesActions';
import { ENABLE_SUBMIT_BUTTON, DISABLED_SUBMIT_BUTTON } from './joinTodayConstants';
import * as MSG from 'app/globalMessages/globalMessagesConstants';
import genericErrorHandler from 'app/utils/serverErrors';

const { joinTodaySubmitUrl } = window.inlineJoinToday;

export const enabledSubmitButton = () => ({
  type: ENABLE_SUBMIT_BUTTON
});

export const disabledSubmitButtonDispatch = () => ({
  type: DISABLED_SUBMIT_BUTTON
});

export const submitRegisterProspect = (formData, MSG_PROSPECT_REGISTER_SUCCESS, scrollCallBack, callback) =>
  dispatch => {
    dispatch(disabledSubmitButtonDispatch());
    ajaxRequest('POST', joinTodaySubmitUrl, formData).then(
      (res) => {
        dispatch(enabledSubmitButton());
        const TIMEOUT_MS = 3000;
        if (res.success) {
          if (scrollCallBack === null) {
            window.scrollTo(0, 0);
          } else {
            scrollCallBack();
          }
          dispatch(setServerMsg(MSG_PROSPECT_REGISTER_SUCCESS, MSG.TYPE_SUCCESS, true));
          setTimeout(() => {
            callback();
          }, TIMEOUT_MS);
        } else {
          window.scrollTo(0, 0);
          dispatch(setServerMsg(res.error, MSG.TYPE_ERROR, true));
        }
        return res;
      },
      ({ status }) => {
        genericErrorHandler(dispatch, status);
      });
  };


export const setErrorMessageRegisterProspect = (MsgError) =>
  dispatch => {
    console.log(MsgError);
    dispatch(setServerMsg(MsgError, MSG.TYPE_ERROR, true));
  };
