import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormError, FormGroup, FormActions } from 'react-forms';
import OptionIntl from 'app/formComponents/optionIntl/optionIntl';
import genericErrorHandler from 'app/utils/serverErrors';
import { ajaxRequest } from 'app/utils/helpers';

class RegionComponent extends Component {

  constructor(props) {
    super();
    this.state = {
      regionInputInUse: false,
    };
    this.onChangeInputRegion = this.onChangeInputRegion.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    const {
      formName, setInputValue, getRegions, regionsUrl, currentSelectedCountry, selectedRegion
    } = nextProps;

    if (currentSelectedCountry && this.props.currentSelectedCountry !== currentSelectedCountry) {
      getRegions(regionsUrl, currentSelectedCountry);
    }

    if (selectedRegion !== this.props.selectedRegion) {
      setInputValue(formName, {
        'region': selectedRegion
      });
    }
  }

  onChangeInputRegion(event) {
    const { onChangeInputRegion, cityFormName, postalCodeFormName } = this.props;
    this.setState({
      regionInputInUse: true,
    })
    const inputValue = event.target.value;
    onChangeInputRegion(<OptionIntl value="" id="addressForm.country.default" />, cityFormName, postalCodeFormName);
  }

  render() {
    const {
      regions,
      formName,
      handleValidForm,
      handleInvalidForm,
      validation,
      selectedRegion,
      selectedRegionName,
      regionName,
      displayCityColoniaPostCodeFields
    } = this.props;
    const regionsUnavailable =
      regions.length === 0
      || regions === null
      || regions[0].isocode === null;

    return (
      <div className={(regionsUnavailable) ? 'display-none' : ''}>
        <div className="row">
          <div className="column large-12">
            <FormGroup
              formName={formName}
              handleValidForm={handleValidForm}
              handleInvalidForm={handleInvalidForm}
              validation={validation}
            >
              <div className="form-input">
                <div className="form-input__input-used">
                  {this.state.regionInputInUse ?
                    <label htmlFor={regionName} className="mini-label">
                      <FormattedMessage id="security.address.state.label" />
                    </label> : ''}
                  <select className="region-selector__select" name={regionName} id={regionName} value={selectedRegion !== null ? selectedRegion : ''} onChange={this.onChangeInputRegion}>
                    <OptionIntl value="" id="addressForm.region.default" />
                    {regions.map((region) =>
                      <option key={`region-${region.isocode}_${region.name}`} value={region.isocode}>{region.name}</option>
                    )}
                  </select>
                </div>
                <FormError forInput={regionName} />
              </div>
              {
                displayCityColoniaPostCodeFields ?
                  <div>
                    <div className="form-input">
                      <label className="form-input__label" htmlFor="city">
                        <FormattedMessage id="security.address.city.label" />
                      </label>
                      <input className="form-input region-selector__city" type="text" name="city" id="city" value="" required />
                      <FormError forInput="city" />
                    </div>
                    <div className="form-input">
                      <label className="form-input__label" htmlFor="colonia">
                        <FormattedMessage id="security.address.district.label" />
                      </label>
                      <input className="form-input region-selector__colonia" type="text" name="colonia" id="colonia" value="" required />
                      <FormError forInput="colonia" />
                    </div>
                    <div className="form-input form-input__postal-code">
                      <label className="form-input__label" htmlFor="postalCode">
                        <FormattedMessage id="security.address.postcode.label" />
                      </label>
                      <input maxLength="50" className="form-input region-selector__postalcode" type="text" name="postalCode" id="postalCode" value="" required />
                      <FormError forInput="postalCode" />
                    </div>
                  </div>
                  : ''
              }
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }
}

RegionComponent.defaultProps = {
  regionName: 'region'
};

RegionComponent.propTypes = {
  regionName: PropTypes.string,
  postalCodeFormName: PropTypes.string,
  cityFormName: PropTypes.string,
  regionsUrl: PropTypes.string.isRequired,
  regions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRegions: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  currentSelectedCountry: PropTypes.string,
  formName: PropTypes.string.isRequired,
  handleInvalidForm: PropTypes.func.isRequired,
  handleValidForm: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  selectedRegion: PropTypes.string,
  selectedRegionName: PropTypes.string,
  displayCityColoniaPostCodeFields: PropTypes.bool,
  onChangePostalCode: PropTypes.func
};

const SET_AVAILABLE_REGIONS = 'SET_AVAILABLE_REGIONS';
const SET_CURRENT_REGION = 'SET_CURRENT_REGION';

const setRegions = (regions) => ({
  type: SET_AVAILABLE_REGIONS,
  regions
});

const setRegion = (region) => ({
  type: SET_CURRENT_REGION,
  selectedRegion: region
});

const setClearPostalCode = () => ({
  type: 'SET_CLEAR_POSTAL_CODE',
});

const getRegions = (getRegionsUrl, isoCode) =>
  dispatch =>
    ajaxRequest('GET', getRegionsUrl, { isoCode }).then((res) => dispatch(setRegions(res)), ({ status }) => genericErrorHandler(dispatch, status));


const onChangeInputRegion = (blankMsg, cityFormName, postalCodeFormName) =>
  dispatch => {
    dispatch(FormActions.setInputValue(postalCodeFormName, { postalCode: '' }));
    dispatch(FormActions.setInputValue(cityFormName, { city: blankMsg }));
  };

const initialState = {
  regions: [],
  currentSelectedCountry: '',
  currentlySelectedRegion: ''
};

export const RegionSelectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVAILABLE_REGIONS:
      return {
        ...state,
        regions: action.regions
      };
    case SET_CURRENT_REGION:
      return {
        ...state,
        currentlySelectedRegion: action.currentlySelectedRegion
      };
    case 'SET_CLEAR_POSTAL_CODE':
      return {
        ...state,
        type: 'SET_CLEAR_POSTAL_CODE'
      }
    default:
      return state;
  }
};


const mapStateToProps = (state, props) => {
  const countrySelector = state.Forms[props.countryFormName] || { values: {} };
  const currentSelectedCountry = (countrySelector.values.country && countrySelector.values.country.isocode) ?
    countrySelector.values.country.isocode :
    countrySelector.values.country;
  return ({
    regions: state.RegionSelector.regions,
    currentSelectedCountry,
    ...FormActions
  });
};

const mapDispatchToProps = (dispatch) => ({
  getRegions: (getRegionsUrl, isoCode) => {
    dispatch(getRegions(getRegionsUrl, isoCode));
  },
  setRegion: (region) => {
    dispatch(setRegion(region));
  },
  setInputValue: (parms) => {
    dispatch(FormActions.setInputValue(parms));
  },
  onChangeInputRegion: (postalCodeFormName, inputValue) => {
    dispatch(onChangeInputRegion(postalCodeFormName, inputValue));
  }
});

export const RegionSelector = connect(mapStateToProps, mapDispatchToProps)(RegionComponent);
