import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormError, FormGroup } from 'react-forms';

export default class EmailComponent extends Component {
  constructor() {
    super();
    this.state = {
      emailInputInUse: false,
    };
    this.sectionInputInUseHandler = this.sectionInputInUseHandler.bind(this);
  }

  sectionInputInUseHandler(event) {
    event.preventDefault();
    let inputStateHandler;

    if (event.target.value.length > 0) {
      inputStateHandler = true;
    } else {
      inputStateHandler = false;
    }

    this.setState({
      emailInputInUse: inputStateHandler,
    });
  }

  render() {
    const {
      formName, placeholders, ...handlersAndValidation
    } = this.props;
    return (
      <FormGroup
        formName={formName}
        className="form-group--no-margin"
        {...handlersAndValidation}
      >
        <div className="row">
          <div className="columns large-12">
            <div className="form-input">
              <div className="form-input__input-used">
                {this.state.emailInputInUse ? <label htmlFor="email" className="mini-label"><FormattedMessage id="security.ui.auth.username" description="Email" /></label> : ''}
                <input
                  className="form-input__input"
                  type="email"
                  id="email"
                  name="email"
                  value=""
                  onChange={event => this.sectionInputInUseHandler(event)}
                  placeholder={`${placeholders ? placeholders.email : 'Correo Electrónico'}`}
                />
              </div>
              <FormError forInput="email" />
            </div>
          </div>
        </div>
      </FormGroup>
    );
  }
}

EmailComponent.propTypes = {
  formName: PropTypes.string.isRequired,
  placeholders: PropTypes.object
};
