import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormActions } from 'react-forms';
import { CitySelector } from './components/citySelector';
import { PostalCodeSelector } from './components/postalCode';
import { RegionSelector } from './components/regionSelector';

const ProspectPostCodeSuggestionComponent = (props) => {
  const postCodeSuggestionJSX = (
    <div className="row">
      <div className="column large-12 medium-12 small-12">
        <PostalCodeSelector
          regionFormName={props.regionFormName}
          cityFormName={props.cityFormName}
          formName={props.postalCodeFormName}
          handleValidForm={props.handleValidForm}
          handleInvalidForm={props.handleInvalidForm}
          validation={props.validation}
          selectedRegion={props.selectedRegion}
          selectedCity={props.selectedCity}
          selectedCityName={props.selectedCityName}
          selectedColonia=""
          selectedColoniaName=""
          postalCodesUrl={props.postalCodesUrl}
          suggestedPostalCode={props.suggestedPostalCode}
        />

        <RegionSelector
          formName={props.regionFormName}
          regionFormName={props.regionFormName}
          cityFormName={props.cityFormName}
          postalCodeFormName={props.postalCodeFormName}
          regionsUrl={props.regionsUrl}
          countryFormName={props.countryFormName}
          handleValidForm={props.handleValidForm}
          handleInvalidForm={props.handleInvalidForm}
          validation={props.validation}
          selectedRegion={props.selectedRegion}
          selectedRegionName=""
        />
        <CitySelector
          formName={props.cityFormName}
          handleValidForm={props.handleValidForm}
          handleInvalidForm={props.handleInvalidForm}
          validation={props.validation}
          selectedRegion={props.selectedRegion}
          postalCodesUrl={props.postalCodesUrl}
        />
      </div>
    </div>
  );

  return (
    <div className="postcode-suggestion">
      {postCodeSuggestionJSX}
    </div>
  );
};

ProspectPostCodeSuggestionComponent.propTypes = {
  cityFormName: PropTypes.string.isRequired,
  postalCodeFormName: PropTypes.string.isRequired,
  handleInvalidForm: PropTypes.func.isRequired,
  handleValidForm: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  selectedRegion: PropTypes.string,
  selectedCity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  selectedCityName: PropTypes.string,
  postalCodesUrl: PropTypes.string,
  regionsUrl: PropTypes.string,
  countryFormName: PropTypes.string,
  regionFormName: PropTypes.string,
  suggestedPostalCode: PropTypes.string,
};

const mapStateToProps = (state, props) => {
  const { regionFormName, cityFormName } = props;
  const regionSelector = state.Forms[regionFormName] || { values: {} };
  const selectedRegion = (regionSelector.values.region) ? regionSelector.values.region : null;
  const citySelector = state.Forms[cityFormName] || { values: {} };
  const selectedCity = (citySelector.values.city) ? citySelector.values.city : null;
  const suggestedPostalCode = null;
  return {
    selectedRegion,
    selectedCity,
    selectedCityName: state.PostCodeSuggestion.CitySelector.selectedCityName,
    suggestedPostalCode
  };
};

export const ProspectPostCodeSuggestion = connect(mapStateToProps, {
  ...FormActions
})(ProspectPostCodeSuggestionComponent);
