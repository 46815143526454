/* eslint-disable */
import React from 'react';
import { FormError, FormGroup } from 'react-forms';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const CellPhoneNumber = ({ usePlaceholder, ...otherProps }, context) =>
  (<div className={otherProps.cellPhoneComponent ? "columns large-6 medium-6 small-12" : ''}> 
    {otherProps.cellPhoneComponent ?  <div className="register-icon" style={{ fontSize: "1em", top: "14px", left: "-13px" }} /> : ''}
    <FormGroup
      {...otherProps}
    >
      <div className="form-input">
        <div className="form-input__input-used">
          {
            otherProps.isInputInUse('cellphone') &&
            <label htmlFor="cellPhoneNumber.label" className="mini-label">
              <FormattedMessage id="security.address.cellPhoneNumber" />
            </label>
          }
          <input
            type="tel"
            name="cellPhoneNumber"
            id="cellPhoneNumber"
            value=""
            required
            onChange={(event) => otherProps.inputInUseHandler(event, 'cellphone')}
            placeholder={usePlaceholder ? '*Teléfono celular' : ''}
          />
        </div>
        <FormError forInput="cellPhoneNumber" />
      </div>
    </FormGroup>
  </div>

  );

CellPhoneNumber.propTypes = {
  usePlaceholder: PropTypes.bool
};

CellPhoneNumber.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default CellPhoneNumber;
