/* eslint-disable no-magic-numbers */
/* eslint-disable react/no-did-mount-set-state */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormGroupAsync, FormActions } from 'react-forms';
import PropTypes from 'prop-types';
import ModalWrapper from 'app/modal/modalWrapper';
import { scrollToError } from 'app/formComponents/formComponentsUtils';
import Name from 'app/formComponents/nameFormGroup/nameFormGroup';
import EmailComponent from 'app/formComponents/emailTextInput/emailComponent';
import { ProspectPostCodeSuggestion } from 'app/formComponents/prospectPostCodeSuggestion/prospectPostCodeSuggestion';
import CountrySelector from 'app/formComponents/countrySelector/countrySelector';
import CellPhoneNumber from 'app/formComponents/phoneNumber/cellPhoneNumber';
import ProspectRegOtherInfo from 'app/formComponents/prospectRegOtherInfoGroup/prospectRegOtherInfoComponent';
import { submitRegisterProspect, setErrorMessageRegisterProspect } from 'app/prospect/joinTodayFormActions';
import GlobalMessages from 'app/globalMessages/globalMessagesApp';

import { getCookie, setCookie } from 'app/utils/helperCookies';

// Local modules
import { HybrisEventsHandler as GoogleAnalyticsEvents } from '../GoogleAnalyticsEvents';

// Configurations
const { getCountriesUrl, postalCodesUrl } = window.inlineJoinToday;
const { getRegionsUrl } = window.inlineGlobalConfig;
const { joinTodayValidation } = window.inlineJoinToday;

const GA_EVENT_DEFAULTS = {
  ec: 'Modal',
  el: 'Modal: ¿Quieres vender?'
};

class ModalFormProspectRegisterComponent extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      isModalOpen: false,
      displayModal: false,
      inputsInUse: new Set()
    };

    this.context = context;
    this.formName = 'joinToday';
    this.formGroups = new FormGroupAsync({
      formName: this.formName,
      groups: [
        'name',
        'email',
        'cellPhoneNumber',
        'countrySelector',
        'postCodeSuggestionRegion',
        'postCodeSuggestionCity',
        'postCodeSuggestionPostalCode',
        'regOtherInfo',
      ]
    });

    this.onSubmit = this.onSubmit.bind(this);
    this.modalToggle = this.modalToggle.bind(this);

    this.closeModal = this.closeModal.bind(this);

    // Google Analytics events handler
    this.gaEvents = GoogleAnalyticsEvents();

    this.openedTime = Date.now();

    this.isInputInUse = this.isInputInUse.bind(this);
    this.inputInUseHandler = this.inputInUseHandler.bind(this);
  }

  componentDidMount() {
    if (getCookie('showProspectModal') === null || getCookie('showProspectModal') === '') {
      this.setState({
        displayModal: true
      });
      setCookie('showProspectModal', 'true', 1);
    } else {
      this.setState({
        displayModal: !!(getCookie('showProspectModal') !== 'false')
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const element = document.getElementById('registration-wrapper');
    this.formGroups
      .each(group => this.props.triggerValidate(group))
      .then(response => {
        this.props.submitRegisterProspect(response, this.context.intl.formatMessage({ id: 'account.prospecto.created.success.message' }), () => { element.scrollIntoView(true); }, () => {
          // Send Completion event
          this.gaEvents.send({
            ...GA_EVENT_DEFAULTS,
            ea: 'enviado',
            ev: parseInt((Date.now() - this.openedTime) / 1000, 10)
          });

          // Toggle modal
          this.modalToggle(false);
        });
      })
      .fail((err) => {
        let msgError = null;
        const values = Object.values(err);
        if (values.length > 0) {
          const [value] = values;
          msgError = value;
        }
        this.props.setErrorMessageRegisterProspect(msgError);
        element.scrollIntoView(true);
      });
  }

  isInputInUse(inputName) {
    return this.state.inputsInUse.has(inputName);
  }

  inputInUseHandler(event, inputName) {
    event.preventDefault();

    const {
      inputsInUse
    } = this.state;

    if (event.target.value.length > 0) {
      inputsInUse.add(inputName);
    } else {
      inputsInUse.delete(inputName);
    }

    this.setState({
      inputsInUse
    });
  }

  modalToggle(trackEvent = true) {
    // GA Events handler
    if (trackEvent) {
      if (!this.state.isModalOpen) {
        // Send Analytics event: Opened
        this.gaEvents.send({
          ...GA_EVENT_DEFAULTS,
          ea: 'apertura'
        });
        // Store opened time
        this.openedTime = Date.now();
      } else {
        // Send Analytics event: Closed 100%
        this.gaEvents.send({
          ...GA_EVENT_DEFAULTS,
          ea: 'cierre'
        });
      }
    }

    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  closeModal() {
    setCookie('showProspectModal', 'false', 1);
    this.setState({
      displayModal: false,
    });
  }

  render() {
    const tempJoinTodayValidation = joinTodayValidation;

    Object.assign(tempJoinTodayValidation, {
      isalwaystrue: true
    });

    Object.assign(tempJoinTodayValidation, {
      isalwaystrue: this.context.intl.formatMessage({ id: 'registerProspectCommand.privacyPolicy.error' })
    });

    Object.assign(tempJoinTodayValidation, {
      isalwaystrue: true
    });

    Object.assign(tempJoinTodayValidation, {
      isalwaystrue: this.context.intl.formatMessage({ id: 'registerProspectCommand.terms.error' })
    });

    const handlersAndValidation = {
      handleValidForm: this.formGroups.resolve,
      handleInvalidForm: this.formGroups.reject,
      validation: tempJoinTodayValidation
    };

    return (
      <Fragment>
        { this.state.displayModal ?
          <div className="floating--sign-up" >
            <button onClick={this.closeModal} className="close-btn">
              <img alt="Cerrar" width="15px" src="https://storage.googleapis.com/betterware-production-media/content/home/Iconos/icono-delete.svg" />
            </button>
            <button onClick={this.modalToggle} className="sign-btn">
              <div className="floating--sign-up--label">¿Quieres vender?&nbsp;</div>
            </button>
          </div>
          : ''
        }
        <ModalWrapper
          isModalOpen={this.state.isModalOpen}
          modalToggle={this.modalToggle}
          maxWidth={600}
        >
          <GlobalMessages
            isSticky
            headerSelector=".checkout-wrapper > .row"
            stickyClass="is-sticky--checkout-fixed"
          />
          <div id="registration-wrapper" className="modal-form-registration">

            <div className="auth-form--registration-wrapper" >

              <p className="auth-form--registration-distributor-title">
                <FormattedMessage id="security.ui.register.distributor.title" />
              </p>

              <p className="auth-form--registration-distributor-subtitle">
                <FormattedMessage id="security.ui.register.distributor.subtitle" />
              </p>

              <p className="auth-form--registration-required-fields">
                <FormattedMessage id="security.ui.register.requiredFields" />
              </p>

              <form onSubmit={this.onSubmit} className="auth-form auth-form--registration" name="registerProspectForm">
                <div className="row">
                  <div className="columns large-12 medium-12 small-12 modal-form-margin">

                    <Name
                      formName={this.formGroups.getName('name')}
                      {...handlersAndValidation}
                    />

                    <CountrySelector
                      countriesUrl={getCountriesUrl}
                      formName={this.formGroups.getName('countrySelector')}
                      hiddenInput
                      {...handlersAndValidation}
                    />

                    <ProspectPostCodeSuggestion
                      postalCodesUrl={postalCodesUrl}
                      regionsUrl={getRegionsUrl}
                      countryFormName={this.formGroups.getName('countrySelector')}
                      regionFormName={this.formGroups.getName('postCodeSuggestionRegion')}
                      cityFormName={this.formGroups.getName('postCodeSuggestionCity')}
                      postalCodeFormName={this.formGroups.getName('postCodeSuggestionPostalCode')}
                      {...handlersAndValidation}
                    />

                    <CellPhoneNumber
                      isInputInUse={this.isInputInUse}
                      inputInUseHandler={this.inputInUseHandler}
                      formName={this.formGroups.getName('cellPhoneNumber')}
                      usePlaceholder
                      {...handlersAndValidation}
                    />

                    <EmailComponent
                      formName={this.formGroups.getName('email')}
                      {...handlersAndValidation}
                    />

                    <ProspectRegOtherInfo
                      formName={this.formGroups.getName('regOtherInfo')}
                      {...handlersAndValidation}
                    />

                    <div className="auth-form__register-btn">
                      <button onClick={this.onSubmit} className="button" disabled={this.props.buttonDisabled}>
                        <FormattedMessage id="security.ui.register.submit" description="Create account" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ModalWrapper>
      </Fragment>
    );
  }
}

ModalFormProspectRegisterComponent.contextTypes = {
  intl: PropTypes.object.isRequired
};

ModalFormProspectRegisterComponent.propTypes = {
  triggerValidate: PropTypes.func.isRequired,
  scrollToError: PropTypes.func.isRequired,
  submitRegisterProspect: PropTypes.func.isRequired,
  setErrorMessageRegisterProspect: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  buttonDisabled: state.JoinTodayReducer.buttonDisabled
});

const mapDispatchToProps = (dispatch) => ({
  triggerValidate: (formName, trigger, shoudDispatchSubmit) => dispatch(FormActions.triggerValidate(formName, trigger, shoudDispatchSubmit)),
  joinTodayClickDispatch: () => {
    dispatch({
      type: 'JOIN_TODAY_FORM_CLICK'
    });
  },
  submitRegisterProspect: (formData, MSG_PROSPECT_REGISTER_SUCCESS, scrollCallBack, callback) => {
    dispatch(submitRegisterProspect(formData, MSG_PROSPECT_REGISTER_SUCCESS, scrollCallBack, callback));
  },
  scrollToError: (className) => {
    dispatch(scrollToError(className));
  },
  setErrorMessageRegisterProspect: (msgError) => {
    dispatch(setErrorMessageRegisterProspect(msgError));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalFormProspectRegisterComponent);
