// Modules
import UniversalAnalytics from 'universal-analytics';

// Configs
const GLOBAL_SCOPE_NAME = '__GAEH';

/**
 * Google Analytics handler
 *
 * @requires window
 * @requires document
 *
 * @version 1.1.0
 */
class AnalyticsHandler {
  /**
   * AnalyticsHandler constructor method
   *
   * @param {String} accountId
   * @param {UniversalAnalytics.visitorOptions} visitorOptions
   * @param {object} persistentData
   */
  constructor(accountId, visitorOptions, persistentData) {
    // Universal Analytics instance
    this.visitor = UniversalAnalytics(accountId, visitorOptions);

    // Set the persistent data
    if (persistentData) {
      Object.keys(persistentData).forEach(key => {
        const value = persistentData[key];
        // Set value as persistent data
        if (typeof value !== 'undefined') {
          this.set(key, value);
        }
      });
    }
  }

  /**
   * @param {String} key
   * @param {*} value
   * @returns {void}
   */
  set(key, value) {
    return this.visitor.set(key, value);
  }

  /**
   * Get the persistent params that may vary if there's an artificial page redirect like react-router.
   *
   * @returns {object} params
   */
  getVariablePersistentParams() {
    return {
      dt: document.title,
      dp: window.location.href
    };
  }

  /**
   * Reference the Global Scope instance (in window object) if exists or creates a new one.
   *
   * @callback default
   * @param {String} accountId
   * @param {UniversalAnalytics.VisitorOptions} visitorOptions
   * @param {object} persistentData
   * @returns {AnalyticsHandler}
  */
  static default(accountId, visitorOptions, persistentData) {
    // Exists in Window scope?
    try {
      const ref = window[GLOBAL_SCOPE_NAME];
      // Is an instance of the Class?
      if (ref instanceof AnalyticsHandler) {
        // Update tracking id if different
        if (ref.accountId !== accountId) {
          ref.accountId = accountId;
        }
        // Update params
        return ref;
      }
    } catch (err) {
      // Ignore error
    }

    // Create new instance
    const instance = new AnalyticsHandler(accountId, visitorOptions, persistentData);
    // Store in Window scope
    window[GLOBAL_SCOPE_NAME] = instance;
    // Return instance
    return instance;
  }

  /**
   * Create and send event to Google
   *
   * @param {UniversalAnalytics.EventParams} params     Event params
   * @returns {Promise<this>}
   */
  send(params = {}) {
    // Merge params with variable persistents
    this.execute('event', params);
  }

  /**
   * Create and send pageview to Google
   *
   * @param {UniversalAnalytics.PageViewParams} params     PageView params
   * @returns {Promise<this>}
   */
  pageView(params) {
    // Merge params with variable persistents
    this.execute('pageview', params);
  }

  /**
   * Create and send timing to Google
   *
   * @param {UniversalAnalytics.TimingParams} params     Timing params
   * @returns {Promise<this>}
   */
  timing(params) {
    // Merge params with variable persistents
    this.execute('timing', params);
  }

  /**
   * Execute analytics command.
   *
   * @param {String} command  Command name
   * @param {object} params   Command params
   * @returns {Promise<this>}
   */
  execute(command = 'event', params = {}) {
    // Merge params with variable persistents
    const mergedParams = {
      ...this.getVariablePersistentParams(),
      ...params
    };
    // Execute event
    return new Promise((resolve, reject) => this.visitor[command](mergedParams, err => {
      // Reject error message
      if (err) {
        return reject(err);
      }
      // Success
      return resolve(this);
    }));
  }
}

// Exports
/**
 * @type {default}
 */
export default AnalyticsHandler.default;
export { AnalyticsHandler };
