// Modules
import Cookies from 'js-cookie';

// Local Modules
import AnalyticsHandler from './AnalyticsHandler';

// Hybris integration function
export const HybrisEventsHandler = () => {
  // Global configurations in hybris
  const {
    gaTrackingId,
    locale,
  } = window.inlineGlobalConfig || {};
  // Get instance
  const events = AnalyticsHandler(gaTrackingId, undefined, {
    ds: 'web',
    cid: Cookies.get('SESSION'),
    dh: window.location.host,
    geoid: `${locale.country}`.toUpperCase(),
    ul: locale.language,
    cd: window.pageType
  });
  // Return instance
  return events;
};

// Export
export default AnalyticsHandler;
