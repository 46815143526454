import OptionIntl from 'app/formComponents/optionIntl/optionIntl';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormGroup,
  FormError
} from 'react-forms';


class ProspectRegOtherInfoFormGroup extends Component {
  constructor() {
    super();
    this.onChangeHearAboutUs = this.onChangeHearAboutUs.bind(this);
  }

  onChangeHearAboutUs() {
  }

  render() {
    return (
      <FormGroup {...this.props}>
        <div className="row">
          <div className="columns large-12">
            <div className="form-input">
              <div className="form-input__input-used">
                <label className="mini-label" htmlFor="howDidYouHearAboutUs">
                  <FormattedMessage id="security.ui.auth.hear.about.us" />
                </label>
                <select onChange={this.onChangeHearAboutUs} className="form-input__input" type="text" id="howDidYouHearAboutUs" name="howDidYouHearAboutUs" value="" style={{ border: 'none' }}>
                  <OptionIntl id="security.ui.auth.hear.about.us.select" />
                  <OptionIntl value="family" id="security.ui.auth.hear.about.us.family" />
                  <OptionIntl value="radio" id="security.ui.auth.hear.about.us.radio" />
                  <OptionIntl value="catalogue" id="security.ui.auth.hear.about.us.catalogue" />
                  <OptionIntl value="facebook" id="security.ui.auth.hear.about.us.facebook" />
                  <OptionIntl value="internet" id="security.ui.auth.hear.about.us.internet" />
                  <OptionIntl value="other" id="security.ui.auth.hear.about.us.other" />
                </select>
              </div>
            </div>
          </div>
        </div>
        <p className="auth-form__desc-txt"><FormattedMessage id="security.ui.auth.toc" /></p>
        <div className="row auth-form__tandc">
          <div className="columns large-12 small-12 medium-12">
            <label htmlFor="privacyPolicy">
              <span className="form-checkbox">
                <input name="privacyPolicy" id="privacyPolicy" className="form-checkbox__input" type="checkbox" checked="checked" />
                <span className="form-checkbox__checkbox" />
              </span>
              <span className="form-checkbox__label">
                <a href="/politicas-de-privacidad" target="_blank"><FormattedMessage id="security.ui.auth.privacyPolicy" /></a>
              </span>
              <FormError forInput="privacyPolicy" />
            </label>
          </div>
        </div>
      </FormGroup>
    );
  }
}

export default ProspectRegOtherInfoFormGroup;
