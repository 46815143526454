import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import customEvents from 'app/customEvents/customEventsMiddleware';
import ModalFormProspectReducer from './/modalFormProspectRegisterReducer';
import { FormReducer as Forms } from 'react-forms';
import JoinTodayReducer from 'app/prospect/joinTodayReducer';
import GlobalMessages from 'app/globalMessages/globalMessagesReducer';
import CountrySelector from 'app/formComponents/countrySelector/countrySelectorReducer';
import RegionSelector from 'app/formComponents/regionSelector/regionSelectorReducer';
import PostCodeSuggestion from 'app/formComponents/postCodeSuggestion/postCodeSuggestionReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const ModalFormProspectReducers = combineReducers({
  ModalFormProspectReducer,
  GlobalMessages,
  Forms,
  JoinTodayReducer,
  CountrySelector,
  RegionSelector,
  PostCodeSuggestion
});

const store = createStore(
  ModalFormProspectReducers,
  composeEnhancers(
    applyMiddleware(thunk, customEvents)
  )
);

export default store;

