import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OptionIntl from 'app/formComponents/optionIntl/optionIntl';
import { FormError, FormActions, FormGroup } from 'react-forms';
import genericErrorHandler from 'app/utils/serverErrors';
import { simpleAjaxRequest } from 'app/utils/helpers';
import { sortBy, sortedUniqBy, isEmpty, find } from 'lodash';
import { setServerMsg } from 'app/globalMessages/globalMessagesActions';
import * as MSG from 'app/globalMessages/globalMessagesConstants';

const { getDistrictsUrl, getTownAndDistrictsUrl } = window.inlineGlobalConfig;

class PostalCodeComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      suggestedPostalCode: props.suggestedPostalCode,
      postalCodeInputUsed: false,
    };
    this.onChangePostalCode = this.onChangePostalCode.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {
      suggestedPostalCode, setInputValue, formName
    } = nextProps;

    if (suggestedPostalCode) {
      setInputValue(formName, {
        'postalCode': suggestedPostalCode
      });
    }
  }

  onChangePostalCode(event) {
    const { regionFormName, cityFormName, getRegionTownAndDistrictByPostalCode } = this.props;
    let inputStateHandler;
    if (event.target.value.length > 0) {
      inputStateHandler = true;
    } else {
      inputStateHandler = false;
    }

    this.setState({
      postalCodeInputUsed: inputStateHandler
    })

    const postalCode = event.target.value;
    if (postalCode.length == 5) {
      getRegionTownAndDistrictByPostalCode(regionFormName, cityFormName, '', <OptionIntl value="" id="addressForm.country.default" />, postalCode);
    }
  }

  render() {
    const {
      colonias,
      selectedColonia,
      selectedColoniaName,
      suggestedPostalCode,
      ...formProps
    } = this.props;

    return (
      <FormGroup
        {...formProps}
      >
        <div className="form-input form-input__postal-code">
          <div className="form-input__input-used">
            {this.state.postalCodeInputUsed ?
              <label className="form-input__label mini-label" htmlFor="postalCode">
                <FormattedMessage id="security.address.postcode.label" />
              </label> : ''}
            <input
              className="form-input address-form__postcode-input"
              type="text"
              name="postalCode"
              id="postalCode"
              value={suggestedPostalCode}
              maxLength={this.props.validation.rules.postalCode.maxLength}
              onChange={this.onChangePostalCode}
              placeholder="*Código postal"
            />
          </div>
          <FormError forInput="postalCode" />
        </div>

      </FormGroup>
    );
  }
}

PostalCodeComponent.propTypes = {
  formName: PropTypes.string.isRequired,
  handleValidForm: PropTypes.func.isRequired,
  handleInvalidForm: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  colonias: PropTypes.array,
  selectedRegion: PropTypes.string,
  selectedCity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  selectedCityName: PropTypes.string,
  selectedColonia: PropTypes.string,
  selectedColoniaName: PropTypes.string,
  suggestedPostalCode: PropTypes.string,
  getRegionTownAndDistrictByPostalCode: PropTypes.func.isRequired,
  removeColonias: PropTypes.func.isRequired,
  onColoniaSelected: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  postalCodesUrl: PropTypes.string,
  regionFormName: PropTypes.string,
  cityFormName: PropTypes.string,
  changeInputCity: PropTypes.func.isRequired
};

// ------------------------------------------------------------
// CONSTANTS
// ------------------------------------------------------------

// Colonias
const SET_AVAILABLE_COLONIAS = 'SET_AVAILABLE_COLONIAS';
const SET_CURRENT_COLONIA = 'SET_CURRENT_COLONIA';
const REMOVE_COLONIAS = 'REMOVE_COLONIAS';
const SET_CURRENT_REGION = 'SET_CURRENT_REGION';
const SET_AVAILABLE_CITIES = 'SET_AVAILABLE_CITIES';
const SELECTED_TOWN = 'SELECTED_TOWN';
const SET_AUTO_SELECTED_REGION = 'SET_AUTO_SELECTED_REGION'

// ------------------------------------------------------------
// ACTIONS
// ------------------------------------------------------------

const setColonias = (data) => ({
  type: SET_AVAILABLE_COLONIAS,
  data
});

const setColonia = (colonia) => ({
  type: SET_CURRENT_COLONIA,
  data: colonia
});

const setCities = (data) => ({
  type: SET_AVAILABLE_CITIES,
  data
});

const selectedTown = (data) => ({
  type: SELECTED_TOWN,
  idTown: data.idTown,
  town: data.town
});

const removeColonias = () => ({
  type: REMOVE_COLONIAS
});

const setRegion = (region) => ({
  type: SET_CURRENT_REGION,
  idRegion: region.idRegion,
  region: region.region
});

const setAutoSelectedRegion = (region) => ({
  type: SET_AUTO_SELECTED_REGION,
  idRegion: region.idRegion,
  region: region.region
});

const cleanRegion = () => ({
  type: 'CLEAN_REGION',
});

const changeInputRegion = (formName, region) =>
  dispatch => {
    //console.log(`${formName} - ${region} `);
    dispatch(FormActions.setInputValue(formName, { region: region }));
  };

const changeInputCity = (cityFormName, city, cityName) =>
  dispatch => {
    //console.log(`${cityFormName} - ${city} `);
    dispatch(FormActions.setInputValue(cityFormName, { city: city, cityName: cityName }));
  };

const setEmptyCity = (cityFormName, blankMsg) =>
  dispatch => {
    dispatch(FormActions.setInputValue(cityFormName, { city: blankMsg }));
  };

const fetchRegionTownAndDistricts = (regionFormName, cityFormName, baseURL, blankMsg, postalCode) => dispatch => {

  fetch(`${getTownAndDistrictsUrl}?postalCode=${postalCode}`)
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      dispatch(cleanRegion);
      dispatch(setEmptyCity(cityFormName, blankMsg));
      dispatch(setColonias(myJson[0].districts));
      dispatch(setCities(myJson[0].towns));
      dispatch(selectedTown(myJson[0]));
      dispatch(changeInputRegion(regionFormName, myJson[0].idRegion));
      dispatch(changeInputCity(cityFormName, myJson[0].idTown, myJson[0].town));
    })
    .catch(function (error) {
      dispatch(removeColonias());
      window.scrollTo(0, 0);
      dispatch(setServerMsg('account.register.cp.warning', MSG.TYPE_WARNING, true, true));
    });
}
// ------------------------------------------------------------
// REDUCER
// ------------------------------------------------------------

const initialState = {
  colonias: [],
  selectedColonia: null,
  selectedColoniaName: null,
  suggestedPostalCode: ''
};

const normalizeColonias = colonias => sortedUniqBy(sortBy(colonias, [(colonia) => (colonia.name)]), (colonia) => (colonia.id));

export const PostalCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVAILABLE_COLONIAS:
      return {
        ...state,
        colonias: normalizeColonias(action.data),
        suggestedPostalCode: ''
      };

    case REMOVE_COLONIAS:
      return {
        ...state,
        selectedColonia: null,
        selectedColoniaName: null,
        colonias: [],
        suggestedPostalCode: ''
      };

    case SET_CURRENT_COLONIA:
      return {
        ...state,
        selectedColonia: action.data && action.data.id,
        selectedColoniaName: action.data && action.data.name,
        suggestedPostalCode: action.data && action.data.postalCode
      };

    case SET_AUTO_SELECTED_REGION:
      return {
        ...state,
        type: action.type,
        selectedRegionId: action.idRegion,
        selectedRegion: action.region
      };

    case SET_CURRENT_REGION:
      return {
        ...state,
        selectedRegionId: action.idRegion,
        selectedRegion: action.region
      };

    case SELECTED_TOWN:
      return {
        ...state,
        selectedTown: action.town,
        selectedTownId: action.idTown
      };

    default:
      return state;
  }
};

// ------------------------------------------------------------
// CONTAINER
// ------------------------------------------------------------
const mapStateToProps = (state, props) => {
  const { selectedRegion, selectedCity } = props;
  const { colonias, selectedColonia, selectedColoniaName, suggestedPostalCode } = state.PostCodeSuggestion.ColoniaSelector;
  return {
    selectedRegion,
    selectedCity,
    colonias,
    selectedColonia,
    selectedColoniaName,
    suggestedPostalCode,
    ...FormActions
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRegionTownAndDistrictByPostalCode: (regionFormName, cityFormName, baseURL, blankMsg, postalCode) => {
    dispatch(fetchRegionTownAndDistricts(regionFormName, cityFormName, baseURL, blankMsg, postalCode));
  },

  onColoniaSelected: (event, formName, colonia) => {
    dispatch(setColonia(colonia));
    // set postal code
    dispatch(FormActions.setInputValue(formName, { postalCode: colonia.postalCode }));
    // clean postal code error
    //dispatch(FormActions.setSingleValidity(formName, { postalCode: '' }));
  },
  removeColonias: (event) => {
    dispatch(removeColonias(event));
  },
  changeInputRegion: (formName, region) => {
    dispatch(changeInputRegion(formName, region));
  },
  changeInputCity: (cityFormName, city, cityName) => {
    dispatch(changeInputCity(cityFormName, city, cityName));
  },
  setInputValue: (parms) => {
    dispatch(FormActions.setInputValue(parms));
  }
});

export const PostalCodeSelector = connect(mapStateToProps, mapDispatchToProps)(PostalCodeComponent);
