import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OptionIntl from 'app/formComponents/optionIntl/optionIntl';
import { FormError, FormActions, FormGroup } from 'react-forms';
import genericErrorHandler from 'app/utils/serverErrors';
import { simpleAjaxRequest } from 'app/utils/helpers';
import { sortBy, sortedUniqBy, isEmpty, find } from 'lodash';

const { getDistrictsUrl, getTownAndDistrictsUrl } = window.inlineGlobalConfig;

class ColoniaSelectorComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      suggestedPostalCode: props.suggestedPostalCode,
      coloniaInputInUse: false,
    };
    this.coloniaSelected = this.coloniaSelected.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { selectedRegion, selectedCity, setInputValue, formName, colonias } = nextProps;

    if (this.newRegionSelected(selectedRegion)) {
      this.props.removeColonias();
    } else if (this.newCitySelected(selectedCity)) {
      this.props.removeColonias();
      this.fetchOptions(selectedRegion, selectedCity);
    }

    const hasSingleOption = colonias.length === 1;
    if (hasSingleOption) {
      setInputValue(formName, {
        colonia: colonias[0].id
      });
      setInputValue(formName, {
        postalCode: colonias[0].postalCode
      });
    }
  }

  fetchOptions(selectedRegion, selectedCity) {
    this.props.getOptions(getDistrictsUrl, selectedRegion, selectedCity);
  }

  isCityEmpty(selectedCity) {
    return isEmpty(selectedCity);
  }

  newRegionSelected(selectedRegion) {
    return (selectedRegion && selectedRegion !== this.props.selectedRegion);
  }

  newCitySelected(selectedCity) {
    return (selectedCity && selectedCity !== this.props.selectedCity);
  }

  coloniaSelected(event) {
    const colonia = find(this.props.colonias, col => col.id === event.target.value);
    this.setState({
      coloniaInputInUse: true,
    })
    this.props.onColoniaSelected(event, this.props.formName, this.props.postalCodeFormName, colonia);
  }

  render() {
    const {
      colonias,
      selectedColonia,
      selectedColoniaName,
      suggestedPostalCode,
      ...formProps
    } = this.props;

    return (
      <FormGroup
        {...formProps}
      >
        <div className="form-input">
          <div className="form-input__input-used">
            {this.props.fetchingColoniasSpinner ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : ''}
            {this.state.coloniaInputInUse ?
              <label htmlFor="colonia" className="mini-label">
                <FormattedMessage id="security.address.district.label" />
              </label> : ''}
            <input
              type="hidden"
              id="coloniaName"
              name="coloniaName"
              value={selectedColoniaName !== null ? selectedColoniaName : ''}
            />
            <select
              className="colonia-selector__select"
              name="colonia"
              id="colonia"
              value={selectedColonia !== null ? selectedColonia : undefined}
              onChange={this.coloniaSelected}
            >
              <OptionIntl value="" id="addressForm.colonia.default" />
              {
                colonias.map((colonia) => <option key={`colonia-${colonia.id}`} value={colonia.id}>{colonia.name}</option>)
              }
            </select>
          </div>
          <FormError forInput="colonia" />
        </div>

      </FormGroup>
    );
  }
}

ColoniaSelectorComponent.propTypes = {
  formName: PropTypes.string.isRequired,
  postalCodeFormName: PropTypes.string.isRequired,
  handleValidForm: PropTypes.func.isRequired,
  handleInvalidForm: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  colonias: PropTypes.array,
  selectedRegion: PropTypes.string,
  selectedCity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  selectedCityName: PropTypes.string,
  selectedColonia: PropTypes.string,
  selectedColoniaName: PropTypes.string,
  suggestedPostalCode: PropTypes.string,
  getOptions: PropTypes.func.isRequired,
  removeColonias: PropTypes.func.isRequired,
  onColoniaSelected: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  postalCodesUrl: PropTypes.string
};

// ------------------------------------------------------------
// CONSTANTS
// ------------------------------------------------------------

// Colonias
const SET_AVAILABLE_COLONIAS = 'SET_AVAILABLE_COLONIAS';
const SET_CURRENT_COLONIA = 'SET_CURRENT_COLONIA';
const REMOVE_COLONIAS = 'REMOVE_COLONIAS';
const FETCHING_COLONIAS = 'FETCHING_COLONIAS';

// ------------------------------------------------------------
// ACTIONS
// ------------------------------------------------------------

const setColonias = (data) => ({
  type: SET_AVAILABLE_COLONIAS,
  data
});

const setColonia = (colonia) => ({
  type: SET_CURRENT_COLONIA,
  data: colonia
});

const removeColonias = () => ({
  type: REMOVE_COLONIAS
});

const fetchingColonias = () => ({
  type: FETCHING_COLONIAS
});

const getColoniasURL = (postalCode) => `${getTownAndDistrictsUrl}/?postalCode=${postalCode}`; //`${basePostcodesURL}estados/${regionId}/municipios/${coloniaId}/colonias.json`;

const getOptions = (url) => dispatch => simpleAjaxRequest('GET', url).then(
  (res) => (dispatch(setColonias(res[0].districts))), ({ status }) => genericErrorHandler(dispatch, status)
  );

// ------------------------------------------------------------
// REDUCER
// ------------------------------------------------------------

const initialState = {
  colonias: [],
  selectedColonia: null,
  selectedColoniaName: null,
  suggestedPostalCode: '',
  fetchingColoniasSpinner: false,
};

const normalizeColonias = colonias => sortedUniqBy(sortBy(colonias, [(colonia) => (colonia.name)]), (colonia) => (colonia.id));

export const ColoniaSelectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVAILABLE_COLONIAS:
      return {
        ...state,
        colonias: normalizeColonias(action.data),
        suggestedPostalCode: '',
        fetchingColoniasSpinner: false,
      };

    case REMOVE_COLONIAS:
      return {
        ...state,
        selectedColonia: null,
        selectedColoniaName: null,
        colonias: [],
        suggestedPostalCode: ''
      };

    case SET_CURRENT_COLONIA:
      return {
        ...state,
        selectedColonia: action.data && action.data.id,
        selectedColoniaName: action.data && action.data.name,
        suggestedPostalCode: action.data && action.data.postalCode,
      };

    case FETCHING_COLONIAS:
      return {
        ...state,
        fetchingColoniasSpinner: true,
      }

    default:
      return state;
  }
};

// ------------------------------------------------------------
// CONTAINER
// ------------------------------------------------------------
const mapStateToProps = (state, props) => {
  const { selectedRegion, selectedCity } = props;
  const { colonias, selectedColonia, selectedColoniaName, suggestedPostalCode, fetchingColoniasSpinner } = state.PostCodeSuggestion.ColoniaSelector;

  return {
    selectedRegion,
    selectedCity,
    colonias,
    selectedColonia,
    selectedColoniaName,
    suggestedPostalCode,
    fetchingColoniasSpinner,
    ...FormActions,
  };
};

const mapDispatchToProps = (dispatch) => ({

  fetchingColonias: () => { dispatch(fetchingColonias()) },

  getOptions: (getDistrictsUrl, region, city) => {
    const postalCode = document.querySelector('.address-form__postcode-input').value;
    dispatch(getOptions(getColoniasURL(postalCode)));
  },

  onColoniaSelected: (event, formName, postalCodeFormName, colonia) => {
    dispatch(setColonia(colonia));
    // set postal code
    dispatch(FormActions.setInputValue(formName, { postalCode: colonia.postalCode }));
    dispatch(FormActions.setInputValue(postalCodeFormName, { postalCode: colonia.postalCode }));
    // clean postal code error
  },

  removeColonias: (event) => {
    dispatch(removeColonias(event));
  },
});

export const ColoniaSelector = connect(mapStateToProps, mapDispatchToProps)(ColoniaSelectorComponent);
