import React from 'react';
import ModalFormProspectRegisterComponent from './modalFormProspectRegisterComponent';

const ModalFormProspectRegisterApp = () => (
  <div>
    <ModalFormProspectRegisterComponent />
  </div>
);

export default ModalFormProspectRegisterApp;
