import * as CONST from './joinTodayConstants';

const initialState = {
  buttonDisabled: false
};

const JoinTodayReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.ENABLE_SUBMIT_BUTTON: {
      return { ...state, buttonDisabled: false };
    }

    case CONST.DISABLED_SUBMIT_BUTTON: {
      return { ...state, buttonDisabled: true };
    }

    default:
      return state;
  }
};

export default JoinTodayReducer;
