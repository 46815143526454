
export function setCookie(name, value, days) {
  let expires = '';
  const h = 24;
  const s = 60;
  const mm = 1000;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * h * s * s * mm));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}
export function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
    if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length, c.length); }
  }
  return null;
}
export function eraseCookie(name) {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';`;
}
