import React from 'react';
import { render } from 'react-dom';
import store from './modalFormProspectRegisterStore';
import providerHOC from 'app/utils/providerHOC/providerHOC';
import ModalFormProspectRegisterApp from './modalFormProspectRegisterApp';

const ModalFormProspectRegisterRoot = providerHOC(store)(ModalFormProspectRegisterApp);

render(
  <ModalFormProspectRegisterRoot />,
  document.getElementById('modalFormProspectRegisterApp')
);
